import { createStore } from 'vuex'

export default createStore({
  state: {
    url_api: 'https://serviciopagina.upea.bo',
    // Datos generales de usuario
    userAdminData: {},
    Institucion: {},
    InstitucionU: [],
    idArea: 0,
    DecanatoAreas: [],
    Decanato: [],
    Area: [],

    // clickCarrera
    idCarr: '',
    idIns: '',
    nombreCarr: '',

    // getter Carrera / Institucion
    getter: false,
    countGetter: 0,

    // id de Convocatorias/Comunicados/Avisos/Cursos/Seminarios/Ofertas
    idCCACS: '',
    idSOV: '',
    idF: '',

    // Mensajes
    ev: 0,
    evTitle: '',
    evMsg: '',

    // Al
    CarreraU: {},

    // id de Publicaciones/Gaceta/Eventos
    idPGEVM: '',
    idINS: '',

    // mensaje chatbot
    mensajeC: {}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
