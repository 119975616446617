import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'appWrapper',
    component: () => import('@/pages/AppWrapper.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/upeahome/',
        name: 'upeahome',
        component: () => import('@/views/UpeaHome.vue'),
        children: [
          {
            path: '/upea',
            name: 'upea',
            component: () => import('@/views/Upea/Upea.vue')
          },
          {
            path: '/publicaciones/:idIns',
            name: 'publicaciones',
            component: () => import('@/views/Upea/Publicaciones/Publicaciones.vue')
          },
          {
            path: '/edit_p/:idPGEVM',
            name: 'edit_p',
            component: () => import('@/views/Upea/Publicaciones/EditP.vue')
          },
          {
            path: '/new_p/:idINS',
            name: 'new_p',
            component: () => import('@/views/Upea/Publicaciones/NewP.vue')
          },
          {
            path: '/gaceta/:idIns',
            name: 'gaceta',
            component: () => import('@/views/Upea/Gaceta/Gaceta.vue')
          },
          {
            path: '/edit_g/:idPGEVM',
            name: 'edit_g',
            component: () => import('@/views/Upea/Gaceta/EditG.vue')
          },
          {
            path: '/new_g/:idINS',
            name: 'new_g',
            component: () => import('@/views/Upea/Gaceta/NewG.vue')
          },
          {
            path: '/eventos/:idIns',
            name: 'eventos',
            component: () => import('@/views/Upea/Eventos/Eventos.vue')
          },
          {
            path: '/edit_e/:idPGEVM',
            name: 'edit_e',
            component: () => import('@/views/Upea/Eventos/EditE.vue')
          },
          {
            path: '/new_e/:idINS',
            name: 'new_e',
            component: () => import('@/views/Upea/Eventos/NewE.vue')
          },
          {
            path: '/videos/:idIns',
            name: 'videos',
            component: () => import('@/views/Upea/Videos/Videos.vue')
          },
          {
            path: '/edit_v/:idPGEVM',
            name: 'edit_v',
            component: () => import('@/views/Upea/Videos/EditV.vue')
          },
          {
            path: '/new_v/:idINS',
            name: 'new_v',
            component: () => import('@/views/Upea/Videos/NewV.vue')
          },
          {
            path: '/links/:idINS',
            name: 'links',
            component: () => import('@/views/Upea/Institucion/Links.vue')
          },
          {
            path: '/autoridad/:idINS',
            name: 'Autoridad',
            component: () => import('@/views/Upea/Institucion/Autoridad.vue')
          },
          {
            path: '/portada/:idINS',
            name: 'Portada',
            component: () => import('@/views/Upea/Institucion/Portada.vue')
          },
          {
            path: '/cca/:id_car',
            name: 'cca',
            props: true,
            component: () => import('@/views/Carrera/Convocatorias/CCA.vue'),
          },
          {
            path: '/edit_cca/:idCCA',
            name: 'edit_cca',
            props: true,
            component: () => import('@/views/Carrera/Convocatorias/EditCCA.vue')
          },
          {
            path: '/new_cca/:id_car',
            name: 'new_cca',
            props: true,
            component: () => import('@/views/Carrera/Convocatorias/NewCCA.vue')
          },
          {
            path: '/cs/:id_car',
            name: 'cs',
            props: true,
            component: () => import('@/views/Carrera/Cursos/CS.vue'),
          },
          {
            path: '/edit_cs/:idCCA',
            name: 'edit_cs',
            props: true,
            component: () => import('@/views/Carrera/Cursos/EditCS.vue')
          },
          {
            path: '/new_cs/:id_car',
            name: 'new_cs',
            props: true,
            component: () => import('@/views/Carrera/Cursos/NewCS.vue')
          },
          {
            path: '/facilitador/:idCS',
            name: 'facilitador',
            props: true,
            component: () => import('@/views/Carrera/Cursos/Facilitador.vue')
          },
          {
            path: '/edit_f/:idF',
            name: 'edit_f',
            props: true,
            component: () => import('@/views/Carrera/Cursos/EditF.vue')
          },
          {
            path: '/ofer/:id_car',
            name: 'ofer',
            component: () => import('@/views/Carrera/Ofertas/Ofertas.vue')
          },
          {
            path: '/new_ofer/:id_car',
            name: 'new_ofer',
            component: () => import('@/views/Carrera/Ofertas/NewOfertas.vue')
          },
          {
            path: '/edit_ofer/:id_ofer',
            name: 'edit_ofer',
            component: () => import('@/views/Carrera/Ofertas/EditOfertas.vue')
          },
          {
            path: '/serv/:id_car',
            name: 'serv',
            component: () => import('@/views/Carrera/Servicios/Servicios.vue')
          },
          {
            path: '/edit_serv/:id_serv',
            name: 'edit_serv',
            component: () => import('@/views/Carrera/Servicios/EditServicios.vue')
          },
          {
            path: '/new_serv/:id_car',
            name: 'new_serv',
            component: () => import('@/views/Carrera/Servicios/NewServicios.vue')
          },
          {
            path: '/institucion/:id_ins',
            name: 'institucion',
            component: () => import('@/views/Upea/Institucion/Institucion.vue')
          },
          {
            path: '/editIns/:id_ins',
            name: 'editIns',
            component: () => import('@/views/Upea/Institucion/EditIns.vue')
          },
          {
            path: '/new_a/:idIns',
            name: 'new_a',
            component: () => import('@/views/Upea/Institucion/NewAutoridad.vue')
          },
          {
            path: '/edit_a/:idIns',
            name: 'edit_a',
            component: () => import('@/views/Upea/Institucion/EditAutoridad.vue')
          },
          {
            path: '/chatbot/:idIns',
            name: 'chatbot',
            component: () => import('@/views/Carrera/Chatbot/ChatbotView.vue')
          },
          {
            path: '/mensaje/:idTag',
            name: 'mensaje',
            component: () => import('@/views/Carrera/Chatbot/NuevoMensaje.vue')
          },
          {
            path: '/editarMensaje/:idMen',
            name: 'editarMensaje',
            component: () => import('@/views/Carrera/Chatbot/EditarMensaje.vue')
          },
        ]
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/pages/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
