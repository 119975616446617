import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// import "@sweetalert2/theme-dark/dark.min.css";
import loadScript from "vue-plugin-load-script";
import ckeditor from "@ckeditor/ckeditor5-vue";
import "@ckeditor/ckeditor5-build-classic/build/translations/es.js";

axios.defaults.baseURL = "https://serviciopagina.upea.bo";
axios.defaults.headers = {
  Accept: "application/json",
  "content-type": "application/json",
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueSweetalert2)
  .use(loadScript)
  .use(ckeditor)
  .mount("#app");
